import { useMutation } from '@apollo/client'
import startCase from 'lodash/startCase'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ButtonType } from '@src/components/Button'
import { AlertModal } from '@src/components/Modal/AlertModal'
import { TrashSVG } from '@src/components/SVGS/TrashSVG'
import { breakpoints } from '@src/constants/breakpoints'
import { Card } from '@src/hooks/sharedQueries/usePaymentCards/usePaymentCards'

import { DeleteCustomerCardDocument } from './mutations/__generated__/DeleteCustomerCard.graphql-interface'

export const PaymentCard: React.FC<{
  card: Card
  refetchList: () => void
  index?: number
  isExpired?: boolean
}> = ({ card, refetchList, isExpired, index }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { t } = useTranslation('paymentDetails')
  const [showErrorModal, setShowErrorModal] = useState(false)

  const [deleteCard, { loading: deleteCardLoading }] = useMutation(
    DeleteCustomerCardDocument,
    {
      onCompleted: () => {
        setShowDeleteModal(false)
        refetchList()
      },
      onError: () => {
        setShowDeleteModal(false)
        setShowErrorModal(true)
      },
    }
  )

  return (
    <>
      <CardNumberContainer>
        <CardNumber>
          {'••••'} {card.last4} {'-'} {startCase(card.brand || '')}
        </CardNumber>
        {card.exp_year && card.exp_month && (
          <CardExpiry>
            {`${card.exp_month.padStart(2, '0')}/${card.exp_year.slice(2)}`}
            {isExpired && (
              <CardExpired>
                {'•'} {t('expired')}
              </CardExpired>
            )}
          </CardExpiry>
        )}
      </CardNumberContainer>
      <DeleteButtonContainer
        onClick={e => {
          e.stopPropagation()
          setShowDeleteModal(true)
        }}
      >
        <DeleteButton
          id="modal-close-button"
          dataTestId={`delete-saved-card-${index}`}
        />
      </DeleteButtonContainer>

      {/* Modals */}
      <AlertModal
        isOpen={showErrorModal}
        title={t('card_issue')}
        subTitle={t('card_deletion_error')}
        action={{
          text: t('ok'),
          intent: ButtonType.DANGER,
          onClick: () => setShowErrorModal(false),
        }}
        isLeftSidebar={true}
      />
      <AlertModal
        isOpen={showDeleteModal}
        title={t('delete_card')}
        subTitle={t('delete_card_desc')}
        isLoading={deleteCardLoading}
        action={{
          text: t('delete'),
          intent: ButtonType.DANGER,
          isLoading: deleteCardLoading,
          onClick: () =>
            deleteCard({
              variables: {
                cardId: card.id,
              },
            }),
        }}
        cancel={{
          text: t('cancel'),
          onClick: () => {
            setShowDeleteModal(false)
          },
        }}
        isLeftSidebar={true}
        dataTestId="alert-modal-delete-confirmation"
      />
    </>
  )
}

export const CardNumber = styled.p`
  font-weight: 700;
  font-size: 16px;
  margin: 0;
`

const CardNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: ${breakpoints.tablet}px) {
    width: 80%;
  }

  @media (max-width: 550px) {
    width: 75%;
  }

  @media (max-width: ${breakpoints.phablet}px) {
    width: 70%;
  }
`

const CardExpiry = styled.div`
  font-size: 13px;
  display: flex;
  align-items: center;
  align-items: left;
  font-weight: 400;
`

const CardExpired = styled.p`
  font-size: 13px;
  font-weight: 700;
  margin: 0px;
  margin-left: 4px;

  @media (max-width: ${breakpoints.desktop}px) {
    font-size: 16px;
  }
`

const DeleteButtonContainer = styled.button.attrs({
  type: 'button',
})`
  all: unset;
  border-radius: 50px;
  cursor: pointer;
`

const DeleteButton = styled(TrashSVG)`
  transition: transform 0.1s;
  width: 24px;

  @media (min-width: ${breakpoints.desktop}) {
    margin: 24px 0 24px 0;
  }

  &:hover {
    transform: scale(1.2);
  }
`
