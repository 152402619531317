import { useReactiveVar } from '@apollo/client'
import { format } from 'date-fns'
import { without } from 'lodash'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Popover } from 'react-tiny-popover'

import { DotSpan } from '@src/components/DotSpan/DotSpan'
import { FulfilmentOverlay } from '@src/components/FulfilmentOverlay/FulfilmentOverlay'
import { DeliveryLocationSection } from '@src/components/ServicePopover/DeliveryLocationSection'
import { LocationLabel } from '@src/components/ServicePopover/LocationLabel'
import { SectionHeader } from '@src/components/ServicePopover/SectionHeader'
import { SectionContentContainer } from '@src/components/ServicePopover/ServicePopover.styles'
import { TimeSection } from '@src/components/ServicePopover/TimeSection'
import {
  LocationType,
  NarrowFulfilmentMethodInputType,
} from '@src/graphql-types'
import { useCategories } from '@src/hooks/useCategories'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { FulfilmentFilterWhenType } from '@src/hooks/useFulfilmentFilter/validation'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { BagDeliverySVG } from '@src/images/BagDelivery'
import { jwtVar } from '@src/models/customer/jwt'
import {
  ALL_FULFILMENT_FILTER_WHEN_TYPES,
  ALL_FULFILMENT_METHODS_SORTED,
  FULFILMENTS_NO_TABLE,
} from '@src/utils/fulfilment'

import {
  Container,
  DesktopDropdownContent,
  DropdownInnerContainer,
  InnerContainer,
  LabelContainer,
  LargeLabel,
  StyledClockSVG,
  StyledDoubleArrowSVG,
  StyledLocationSVG,
} from './DesktopNavigationStyles'

import { Search } from '../SearchBar/Search'

enum ActiveMenu {
  SERVICE = 'SERVICE',
  TIMESLOT = 'TIMESLOT',
  LOCATION = 'LOCATION',
}

export const DesktopNavigation: React.FC<{
  setShowCategories: (val: boolean) => void
}> = ({ setShowCategories }) => {
  // Set active menu: active menu state styling should persist after menu is closed
  const jwt = useReactiveVar(jwtVar)
  const [activeMenu, setActiveMenu] = useState<ActiveMenu | null>(null)
  const fulfilmentFilter = useFulfilmentFilter()
  const marketplace = useMarketplace()
  const { t } = useTranslation('serviceNavigation')
  const categories = useCategories()

  const allFulfilmentMethods = marketplace.allowOrderToTable
    ? ALL_FULFILMENT_METHODS_SORTED
    : FULFILMENTS_NO_TABLE

  // categories page does not allow service selection
  // so display All, Anytime, and Everywhere
  // and disable the buttons
  const disableServicePopovers = categories.selectedCategories.length > 0
  if (disableServicePopovers) {
    return (
      <>
        <Container>
          <InnerContainer isActive={false} disabled={true}>
            <LabelContainer>
              <BagDeliverySVG id="service" />
              <LargeLabel>{marketplace.allOutletsText || t('all')}</LargeLabel>
            </LabelContainer>
          </InnerContainer>
          <InnerContainer isActive={false} disabled={true}>
            <LabelContainer>
              <StyledClockSVG id="time" />
              <LargeLabel>{t('anytime')}</LargeLabel>
            </LabelContainer>
          </InnerContainer>
          <InnerContainer
            isActive={activeMenu === ActiveMenu.LOCATION}
            disabled={true}
          >
            <LabelContainer>
              <StyledLocationSVG id="location" />
              <LargeLabel>{t('everywhere')}</LargeLabel>
            </LabelContainer>
          </InnerContainer>
          <Search setShowCategories={setShowCategories} />
        </Container>
      </>
    )
  }

  const whenMenuLabel = () => {
    switch (fulfilmentFilter.data.when.type) {
      case FulfilmentFilterWhenType.ANYTIME:
        return t('anytime')
      case FulfilmentFilterWhenType.ASAP:
        return t('now')
      case FulfilmentFilterWhenType.PREORDER:
        return format(fulfilmentFilter.data.when.preorderDate, 'EEE dd MMM')
    }
  }

  // normal outlet list page, so display interactive buttons
  return (
    <>
      <Container>
        {/* Service Popover */}
        <Popover
          isOpen={activeMenu === ActiveMenu.SERVICE}
          positions={['bottom', 'right', 'left', 'top']} // preferred positions by priority
          align={'center'}
          onClickOutside={() => {
            setActiveMenu(prevMenu =>
              prevMenu !== activeMenu ? prevMenu : null
            )
          }}
          content={({ childRect }) => (
            <DropdownInnerContainer width={`${childRect.width}px`}>
              <DesktopDropdownContent>
                <SectionHeader
                  header={t('list_service_header')}
                  subheader={t('list_service_subheader')}
                />
                <SectionContentContainer>
                  <FulfilmentOverlay
                    availableFulfilmentMethods={
                      marketplace.sortedAvailableFulfilmentMethods
                    }
                    selectedFulfilmentMethods={
                      fulfilmentFilter.data.sortedFulfilmentMethods
                    }
                    setSelectedFulfilmentMethods={
                      fulfilmentFilter.setActiveFulfilmentMethods
                    }
                  />
                </SectionContentContainer>
              </DesktopDropdownContent>
            </DropdownInnerContainer>
          )}
        >
          <InnerContainer
            isActive={activeMenu === ActiveMenu.SERVICE}
            onClick={() => {
              setActiveMenu(ActiveMenu.SERVICE)
            }}
            data-test-id="nav-bar-service-container"
          >
            <LabelContainer>
              <BagDeliverySVG id="service" />
              <LargeLabel>
                {fulfilmentFilter.data.nonEmptyFulfilmentMethods.length ===
                allFulfilmentMethods.length
                  ? marketplace.allOutletsText || t('all')
                  : fulfilmentFilter.data.sortedFulfilmentMethods.map(
                      (fulfilment, index) => (
                        <Fragment key={fulfilment}>
                          {index > 0 && <DotSpan />}
                          {t(fulfilment.toLowerCase())}
                        </Fragment>
                      )
                    )}
              </LargeLabel>
            </LabelContainer>
            <StyledDoubleArrowSVG id={`doubleArrow-service`} />
          </InnerContainer>
        </Popover>

        {/* WHEN Popover */}
        {
          // Display WHEN filter if DELIVERY or COLLECTION service is one of the selected fulfilment methods
          fulfilmentFilter.data.nonEmptyFulfilmentMethods.some(
            fulfilmentMethod =>
              [
                NarrowFulfilmentMethodInputType.DELIVERY,
                NarrowFulfilmentMethodInputType.COLLECTION,
              ].includes(fulfilmentMethod)
          ) && (
            <Popover
              isOpen={activeMenu === ActiveMenu.TIMESLOT}
              onClickOutside={() =>
                setActiveMenu(prevMenu =>
                  prevMenu !== activeMenu ? prevMenu : null
                )
              }
              align={'center'}
              positions={['bottom', 'right', 'left', 'top']} // preferred positions by priority
              content={({ childRect }) => (
                <DropdownInnerContainer width={`${childRect.width}px`}>
                  <DesktopDropdownContent>
                    <SectionHeader
                      header={t('list_time_header')}
                      subheader={t('list_time_subheader')}
                    />
                    <SectionContentContainer>
                      <TimeSection
                        availableWhenTypes={ALL_FULFILMENT_FILTER_WHEN_TYPES}
                        selectedWhen={fulfilmentFilter.data.when}
                        onDone={when => {
                          fulfilmentFilter.setWhen(when)
                          setActiveMenu(null)
                        }}
                        showSelection={false}
                      />
                    </SectionContentContainer>
                  </DesktopDropdownContent>
                </DropdownInnerContainer>
              )}
            >
              <InnerContainer
                isActive={activeMenu === ActiveMenu.TIMESLOT}
                onClick={() => {
                  setActiveMenu(ActiveMenu.TIMESLOT)
                }}
                data-test-id="nav-bar-time-container"
              >
                <LabelContainer>
                  <StyledClockSVG id="time" />
                  <LargeLabel>{whenMenuLabel()}</LargeLabel>
                </LabelContainer>
                <StyledDoubleArrowSVG id={`doubleArrow-when`} />
              </InnerContainer>
            </Popover>
          )
        }

        {/* WHERE Popover */}
        <Popover
          isOpen={activeMenu === ActiveMenu.LOCATION}
          positions={['bottom', 'right', 'left', 'top']} // preferred positions by priority
          onClickOutside={() =>
            setActiveMenu(prevMenu =>
              prevMenu !== activeMenu ? prevMenu : null
            )
          }
          align={'center'}
          content={({ childRect }) => (
            <DropdownInnerContainer width={`${childRect.width}px`}>
              <DesktopDropdownContent>
                <SectionHeader
                  header={t('list_location_header')}
                  subheader={t('list_location_subheader')}
                />
                <SectionContentContainer>
                  <DeliveryLocationSection
                    availableLocationTypes={
                      jwt
                        ? marketplace.availableLocationTypes
                        : without(
                            marketplace.availableLocationTypes,
                            LocationType.ADDRESS
                          )
                    }
                    selectedLocation={fulfilmentFilter.data.where.location}
                    setSelectedLocation={locationType => {
                      setActiveMenu(null)
                      fulfilmentFilter.setLocationType(locationType)
                    }}
                    historicalData={fulfilmentFilter.data.where.historicalData}
                    showSubtitles={false}
                    showSelection={false}
                    handleCancelWhenNavigatingAway={() => setActiveMenu(null)}
                  />
                </SectionContentContainer>
              </DesktopDropdownContent>
            </DropdownInnerContainer>
          )}
        >
          <InnerContainer
            isActive={activeMenu === ActiveMenu.LOCATION}
            onClick={() => {
              setActiveMenu(ActiveMenu.LOCATION)
            }}
            data-test-id="nav-bar-location-container"
          >
            <LabelContainer>
              <StyledLocationSVG id="location" />
              <LargeLabel>
                <LocationLabel
                  fulfilmentFilterWhere={fulfilmentFilter.data.where}
                />
              </LargeLabel>
            </LabelContainer>

            <StyledDoubleArrowSVG id={`doubleArrow-where`} />
          </InnerContainer>
        </Popover>
        <Search setShowCategories={setShowCategories} />
      </Container>
    </>
  )
}
