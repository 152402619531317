import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe, CanMakePaymentResult } from '@stripe/stripe-js'
import React, { ReactNode, useMemo, useState } from 'react'

import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { useMarketplace } from '@src/hooks/useMarketplace'

import { LoadingSpinner } from '../LoadingSpinner'

export const StripeElementsProvider: React.FC<{
  amount: number
  children: (props: {
    isGooglePayAvailable: boolean
    isApplePayAvailable: boolean
  }) => ReactNode
}> = ({ amount, children }) => {
  const marketplace = useMarketplace()
  const [isLoading, setIsLoading] = useState(true)
  const [isApplePayAvailable, setApplePayAvailable] = useState(false)
  const [isGooglePayAvailable, setGooglePayAvailable] = useState(false)

  const stripePromise = useMemo(
    () =>
      marketplace.stripePublicId
        ? loadStripe(
            marketplace.stripePublicId,
            marketplace.stripeAccountId
              ? {
                  stripeAccount: marketplace.stripeAccountId,
                }
              : undefined
          )
        : null,
    [marketplace.stripePublicId]
  )

  if (!stripePromise) {
    return (
      <ErrorPage
        errorMessage="Online payments are currently unavailable"
        homePage={false}
        previousPage={false}
      />
    )
  }

  void stripePromise.then((stripe: Stripe | null) => {
    if (stripe) {
      const paymentRequest = stripe.paymentRequest({
        country: marketplace.country.ISO3166Alpha2.toUpperCase(),
        currency: marketplace.country.currency.iso4217.toLowerCase(),
        total: {
          label: marketplace.name || 'Paybox',
          amount,
        },
      })
      paymentRequest
        .canMakePayment()
        .then((result: CanMakePaymentResult | null) => {
          if (result) {
            setApplePayAvailable(result.applePay === true)
            setGooglePayAvailable(result.googlePay === true)
          }
          setIsLoading(false)
        })
        .catch((error: any) => {
          console.error(error)
          return (
            <ErrorPage
              errorMessage="Online payments are currently unavailable"
              homePage={false}
              previousPage={false}
            />
          )
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  })

  if (isLoading) {
    return <LoadingSpinner />
  }

  const options = {
    currency: marketplace.country.currency.iso4217.toLocaleLowerCase(),
    mode: 'payment',
    capture_method: 'manual',
    amount,
    ...(marketplace.approvedPaymentProviderMethods && {
      paymentMethodTypes: marketplace.approvedPaymentProviderMethods,
    }),
    ...(marketplace.onBehalfOfStripeId && {
      onBehalfOf: marketplace.onBehalfOfStripeId,
    }),
    appearance: {
      theme: 'flat',
      labels: 'floating',
      variables: {
        fontSmooth: 'never',
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#000000',
        colorDanger: '#df1b41',
        spacingUnit: '4px',
        borderRadius: '8px',
        fontFamily:
          "'Inter',-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;'",

        // See all possible variables below
      },
      rules: {
        '.RadioIcon': {
          width: '32px',
          height: '32px',
        },
        '.RadioIcon--selected': {
          width: '32px',
          height: '32px',
        },
        '.AccordionItem': {
          borderColor: '#000000',
          backgroundColor: '#f6f6f9',
          paddingLeft: '32px',
          paddingRight: '2px',
          boxShadow: 'none',
          border: 'none',
        },
        '.AccordionItem--selected': {
          borderColor: '#000000',
          boxShadow: 'none',
          border: 'none',
        },
        '.Label--floating': {
          fontSize: '12px',
        },
        '.Label--resting': {
          fontSize: '16px',
        },
        '.Input': {
          paddingTop: '8px',
          borderRadius: '8px',
          outline: '2px solid #ebecf0',
          paddingBottom: '8px',
          fontFamily:
            "'Inter',-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif",
        },
        '.Input--invalid': {
          outline: '2px solid red',
          boxShadow: 'none',
        },
        '.Input:focus': {
          fontWeight: '400',
          outline: '2px solid black',
          boxShadow: 'none',
          color: 'black',
        },
      },
    },
  } as any

  return (
    <Elements stripe={stripePromise} options={options}>
      {children({
        isGooglePayAvailable,
        isApplePayAvailable,
      })}
    </Elements>
  )
}
