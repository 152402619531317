import styled from 'styled-components'

import { Link } from '@src/components/Router/Link'
import { breakpoints } from '@src/constants/breakpoints'

export const HeaderSkeleton = styled.h2`
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: space-between;
  margin: 0px 0px 0px 20px;
  font-size: 17px;

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 0px 24px 0px 32px;
    font-size: 20px;
  }
`

export const HeaderLink = styled(Link)`
  display: flex;
  align-items: flex-end;
  @media (min-width: ${breakpoints.tablet}px) {
    margin: 0px 24px 0px 32px;
  }
  :hover {
    text-decoration: underline;
  }

  h2 {
    margin: 0 0 6px 18px;
    font-size: 17px;
    font-weight: 700;
    @media (min-width: ${breakpoints.tablet}px) {
      font-size: 24px;
      margin: 0 0 6px 0;
    }
  }
`

export const OutletContainer = styled.div`
  padding-top: 24px;
`

export const SliderArrowsContainer = styled.div`
  margin-right: 16px;
  justify-content: center;
  align-items: center;
  display: none;
  height: 30px;
  user-select: none;
  @media (min-width: ${breakpoints.tablet}px) {
    display: flex;
    height: 30px;
    margin-right: 28px;
    align-items: flex-end;
  }

  .swiper-button-disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  button {
    margin-left: 8px;
  }
`

export const HeroContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: '0';
`

export const FeaturedOutletsContainerSkeleton = styled.div`
  overflow-y: hidden;
  margin: 16px 0 0 24px;
  .swiper {
    @media (min-width: ${breakpoints.tablet}px) {
      margin-left: 8px;
      padding-right: 48px;
    }
  }
`

export const FeaturedOutletsContainer = styled.div`
  overflow-y: hidden;
  margin: 0;

  .swiper {
    @media (min-width: ${breakpoints.tablet}px) {
      padding-right: 48px;
    }
  }
`

export const Description = styled.div`
  flex-grow: 1;
  font-size: 12px;
  line-height: 16px;
  margin: 0px 0px 8px 18px;
  color: ${({ theme }) => theme.colors.darkText};
  text-align: left;
  @media (min-width: ${breakpoints.tablet}px) {
    margin: 0px 16px 8px 32px;
    font-size: 14px;
    line-height: 20px;
    > div {
      margin-bottom: 16px;
    }
  }
`

export const BusinessSegmentCategoriesHeader = styled.h2`
  margin: 0px 24px 6px 32px;
`
