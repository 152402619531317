import React from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useTranslation } from 'react-i18next'

import { AllergenTags } from '@src/components/AllergenTags/AllergenTags'
import { ItemCounter } from '@src/components/ItemCounter/ItemCounter'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { BasketItemWithPrices } from '@src/hooks/useTotals/useBasketTotals'

import {
  AllergenContainer,
  Container,
  CounterContainer,
  InnerTitleContainer,
  ItemName,
  ItemPrice,
  LeftContainer,
  OptionItemContainer,
  OuterContainer,
  SingleItemNote,
  StyledCancelSVG,
  TitleContainer,
} from './BasketItem.styles'
import { OptionItem } from './OptionItem'

import { SpicyChiliSVG } from '../../../../SVGS/SpicyChiliSVG'

type SingleItemProps = {
  basketItemWithPrices: BasketItemWithPrices
  allowSingleItemOrderNotes: boolean
  setRemoveItemIdToConfirm: React.Dispatch<React.SetStateAction<null | string>>
}

export const BasketItem: React.FC<SingleItemProps> = ({
  basketItemWithPrices,
  allowSingleItemOrderNotes,
  setRemoveItemIdToConfirm,
}) => {
  const { patchItem, updateQuantity } = useBasketItems()
  const marketplace = useMarketplace()
  const {
    basketItem: { id: basketItemId, singleItemNotes },
    outletMenuItem: {
      allergens,
      spiceLevel,
      name,
      price,
      ageRestricted,
      menuItemId,
      maxPurchaseQuantity,
    },
  } = basketItemWithPrices
  const { t } = useTranslation()
  const formatCurrency = useFormatCurrency()

  const removeNote = () => {
    patchItem({
      basketItemId,
      patchData: {
        singleItemNotes: '',
      },
    })
  }

  const allowAddItem =
    maxPurchaseQuantity === null ||
    basketItemWithPrices.basketItem.quantity < maxPurchaseQuantity
  return (
    <OuterContainer>
      <Container>
        <LeftContainer>
          <TitleContainer>
            <InnerTitleContainer>
              <ItemName>{name}</ItemName>
              {spiceLevel > 0 &&
                Array.from({ length: spiceLevel }).map((_, index) => (
                  <SpicyChiliSVG width={15} height={15} key={index} />
                ))}
            </InnerTitleContainer>
            <ItemPrice>
              {formatCurrency(basketItemWithPrices.totalPrice)}
            </ItemPrice>
          </TitleContainer>
          {allergens && (
            <AllergenContainer>
              <AllergenTags
                iconView
                allergens={allergens}
                ageRestricted={!!ageRestricted}
              />
            </AllergenContainer>
          )}
          {basketItemWithPrices.selectedOptionItems.length > 0 && (
            <OptionItemContainer>
              {basketItemWithPrices.selectedOptionItems.map(optionItem => (
                <OptionItem
                  key={optionItem.id}
                  optionItem={optionItem}
                  quantity={basketItemWithPrices.basketItem.quantity}
                />
              ))}
            </OptionItemContainer>
          )}
        </LeftContainer>

        <CounterContainer>
          <ItemCounter
            dataTooltipId="page-wrapper-tooltip"
            price={price}
            count={basketItemWithPrices.basketItem.quantity}
            handleAdd={() => {
              updateQuantity({
                basketItemId,
                quantity: basketItemWithPrices.basketItem.quantity + 1,
              })

              ReactPixel.track('AddToCart', {
                content_ids: [menuItemId],
                content_type: 'product',
                content_name: name,
                value: price,
                currency: marketplace.country.currency.iso4217,
              })
            }}
            handleRemove={() => {
              if (basketItemWithPrices.basketItem.quantity === 1) {
                setRemoveItemIdToConfirm(basketItemId)
              } else {
                updateQuantity({
                  basketItemId,
                  quantity: basketItemWithPrices.basketItem.quantity - 1,
                })
              }
            }}
            allowAddItem={allowAddItem}
            addItemText={
              !allowAddItem ? t('reached_max_purchase_quantity') : undefined
            }
          />
        </CounterContainer>
      </Container>
      {/* Only if single item notes are allowed */}
      {allowSingleItemOrderNotes && singleItemNotes && (
        <SingleItemNote>
          <StyledCancelSVG
            title={t('remove_item_note')}
            id="remove_note"
            onClick={removeNote}
          />
          <p>{singleItemNotes}</p>
        </SingleItemNote>
      )}
    </OuterContainer>
  )
}
