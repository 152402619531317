import React from 'react'

import { useFormatCurrency } from '@src/hooks/useFormatCurrency'

import {
  MenuItemName,
  MenuItemQuantity,
  MenuItemPrice,
  MenuItemsSubContainer,
  MenuItemTable,
  OptionItemName,
  OptionItemSubContainer,
  OptionItemPrice,
  OptionItemsContainer,
  StyledCouponSVG,
} from './MenuItems.styles'

import { singleOrderCustomType } from '../queries/getSingleOrderCustomType'

export const MenuItems: React.FC<{ myOrder: singleOrderCustomType }> = ({
  myOrder,
}) => {
  const formatCurrency = useFormatCurrency()
  return (
    <MenuItemTable>
      {myOrder.groupedItems.map(groupedItem => (
        <React.Fragment key={groupedItem.id}>
          <MenuItemsSubContainer>
            <MenuItemQuantity>
              {'x'} {groupedItem.quantity}
            </MenuItemQuantity>
            <MenuItemName>{groupedItem.name}</MenuItemName>
            <MenuItemPrice>
              {groupedItem.isAddOnItem && <StyledCouponSVG id="offer-item" />}
              {formatCurrency(groupedItem.totalPrice)}
            </MenuItemPrice>
          </MenuItemsSubContainer>
          {groupedItem.singleItemNotes && (
            <OptionItemSubContainer>
              <OptionItemName>{groupedItem.singleItemNotes}</OptionItemName>
            </OptionItemSubContainer>
          )}
          <OptionItemsContainer>
            {groupedItem?.optionItems?.map(optionItem => (
              <OptionItemSubContainer key={`${groupedItem.id}${optionItem.id}`}>
                <OptionItemName>
                  {'\u2022'} {optionItem.name}
                </OptionItemName>
                <OptionItemPrice>
                  {!!optionItem.price && formatCurrency(optionItem.price)}
                </OptionItemPrice>
              </OptionItemSubContainer>
            ))}
          </OptionItemsContainer>
        </React.Fragment>
      ))}
    </MenuItemTable>
  )
}
