import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const CategorySlideContent = styled.div`
  background: ${({ theme }) => theme.colors.grey};
  border: ${({ theme }) => theme.colors.grey};
  border-radius: 90px;
  width: 80px;
  height: 80px;
  margin: 2px 16px;
  overflow: hidden;
  margin-bottom: 8px;
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.brandHighlight};
    padding: 0;
    margin: 0px 14px;
    margin-bottom: 6px;
  }
`

export const CategorySlideImage = styled.div`
  font-size: 58px;
  text-align: center;
  height: 35px;
  img {
    max-width: 80px;
    max-height: 80px;
  }
`

export const CategorySlideTitle = styled.div`
  text-align: center;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.darkText};
`

export const SegmentCategoriesContainer = styled.div`
  overflow-y: hidden;
  margin: 0 0 0 12px;

  .swiper {
    @media (min-width: ${breakpoints.tablet}px) {
      padding-right: 48px;
    }
  }
`
