import styled from 'styled-components'

import { CouponSVG } from '@src/components/SVGS/CouponSVG'
import { breakpoints } from '@src/constants/breakpoints'

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

export const MenuItemTable = styled.div`
  width: 100%;
  margin: 8px 0 4px 0;
`

export const MenuItemsSubContainer = styled(FlexContainer)`
  justify-content: space-between;
  padding: 8px 16px;
  &:last-child {
    padding-bottom: 4px;
    border-bottom: none;
  }
`

export const MenuItemName = styled.div`
  font-weight: 700;
  font-size: 14px;
  flex: 1;
  margin-right: 16px;
  @media (max-width: ${breakpoints.tablet}px) {
    margin-right: 8px;
    margin-left: 10px;
  }
`

export const MenuItemPrice = styled(FlexContainer)`
  font-weight: 400;
`

export const StyledCouponSVG = styled(CouponSVG)`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  color: #2e3333;
`

export const MenuItemQuantity = styled.div`
  font-weight: 400;
  display: flex;
  align-items: center;
  @media (min-width: ${breakpoints.largeTablet}px) {
    padding-right: 28px;
  }
`

export const OptionItemsContainer = styled.div`
  width: 100%;
`

export const OptionItemSubContainer = styled(MenuItemsSubContainer)`
  padding: 4px 16px;
  border-bottom: none;
`

export const OptionItemName = styled.div`
  font-size: 12px;
  margin-left: 64px;
  @media (max-width: ${breakpoints.tablet}px) {
    margin-left: 48px;
  }
`

export const OptionItemPrice = styled.div`
  font-size: 12px;
  text-align: right;
  line-height: 24px;
`
