import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const EmptyBasketOuterContainer = styled.div`
  height: 0;
  padding: 16px;

  @media (min-width: ${breakpoints.tabletMisc}px) {
    padding: 32px;
  }
`

export const EmptyBasketContainer = styled.div`
  background-color: #f6f6f9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  padding: 64px 16px;
  margin-bottom: 16px;
`
