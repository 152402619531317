import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { ButtonType } from '@src/components/Button'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { useOutletFulfilmentData } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilmentData/useOutletFulfilmentData'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { useBasketOutletId } from '@src/hooks/useBasketOutletId'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { convertToSlug } from '@src/utils/convertToSlug'

import {
  ButtonContainer,
  Container,
  StyledButton,
  Title,
  Underlay,
  SubTitle,
} from './MenuItemModalAlertStyles'

// TODO: This should probably just be swapped out for a generic alert modal
export const BasketClearRequiredAlert: React.FC<{
  onCancel: () => void
  onSubmit: () => void
}> = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation('menuItem')
  const basketItems = useBasketItems()
  const { basketOutletId } = useBasketOutletId()
  const { urlPath } = useMarketplace()
  const outletFulfilment = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const history = useHistory()

  const { data: basketOutletData, loading } = useOutletFulfilmentData({
    outletId: basketOutletId,
    fulfilmentLocation: outletFulfilment.data.currentFulfilment.location,
  })

  const handleSubmit = () => {
    basketItems.clear({ shouldResetCheckoutRouter: false })
    onSubmit()
  }

  if (loading) return <LoadingSpinner />
  if (!basketOutletData?.outlet) {
    return null
  }

  const handleGoBack = () => {
    if (basketOutletData.outlet) {
      history.push(
        `/${urlPath}/${basketOutletId}/${convertToSlug(
          basketOutletData.outlet.displayName
        )}/menu`
      )
    }
  }

  return (
    <>
      <Underlay onClick={onCancel} />
      <Container>
        <Title>
          {t('new_order_title', {
            current_outlet: outletFulfilment.outlet.displayName,
          })}
        </Title>
        <SubTitle>{t('new_order_description')}</SubTitle>
        <ButtonContainer>
          <StyledButton
            buttonType={ButtonType.PRIMARY}
            content={t('new_order_confirm')}
            onClick={handleSubmit}
          />
          <StyledButton
            buttonType={ButtonType.SECONDARY}
            content={t('take_me_back', {
              previous_outlet: basketOutletData.outlet.displayName,
            })}
            onClick={handleGoBack}
          />
          <StyledButton
            buttonType={ButtonType.TERTIARY}
            content={t('cancel')}
            onClick={onCancel}
          />
        </ButtonContainer>
      </Container>
    </>
  )
}
