import { useReactiveVar } from '@apollo/client'
import { Formik } from 'formik'
import { kebabCase, toLower, toUpper, upperFirst } from 'lodash'
import React, { useRef, useState, useEffect } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'

import { ButtonType } from '@src/components/Button'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { FulfilmentWhenLabel } from '@src/components/FulfilmentWhenLabel/FulfilmentWhenLabel'
import { UncontrolledTextInput } from '@src/components/Inputs'
import { AlertModal } from '@src/components/Modal/AlertModal'
import { Totals } from '@src/components/Totals'
import { breakpoints } from '@src/constants/breakpoints'
import {
  NarrowFulfilmentMethodInputType,
  MerchantType,
} from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { CurrentFulfilmentType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'
import { MainRouteName, useAccountRouter } from '@src/hooks/useAccountRouter'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { useSafeArea } from '@src/hooks/useSafeArea'
import { useServiceComponentModal } from '@src/hooks/useServiceComponentModal/useServiceComponentModal'
import { useBasketTotals } from '@src/hooks/useTotals/useBasketTotals'
import { jwtVar } from '@src/models/customer/jwt'
import { screenResolutionVar } from '@src/models/screenResolution'
import { LocationRequiredAlert } from '@src/pages/OutletPage/OutletMenu/MenuItemModal/LocationRequiredAlert/LocationRequiredAlert'
import { ONE_MINUTE } from '@src/pages/OutletPage/OutletPage'
import { imageJitURL } from '@src/utils/imageJitURL'

import { BasketItems } from './BasketItems'
import {
  FoodAllergyNotice,
  Container,
  Subheader,
  WarningText,
  SpaceBetweenRow,
  FulfilmentButtonContainer,
  ExpandArrows,
  OrderNoteTitle,
  StyledClearButton,
  InputContainer,
  RestaurantLogo,
} from './BasketSummary.styles'
import { DiscountCodeSection } from './DiscountCodeSection'

import { CheckoutButtonWrapperMobile } from '../CheckoutButtonWrapperMobile'
import { CheckoutButton } from '../FormElements.styles'

export const BasketSummary: React.FC = () => {
  const { t } = useTranslation('checkout')
  const outletFulfilment = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const { openServiceComponentModal } = useServiceComponentModal()
  const [allergyNoticeOpen, setAllergyNoticeOpen] = useState(false)
  const [clearBasketWarningOpen, setClearBasketWarningOpen] = useState(false)
  const basketTotals = useBasketTotals()
  const basketItems = useBasketItems()
  const marketplace = useMarketplace()
  const { width } = useReactiveVar(screenResolutionVar)
  const { safeAreaInsetBottom } = useSafeArea()
  const merchantURLPath = marketplace.urlPath
  const { setRoute } = useAccountRouter()
  const minimumSubtotalErrorRef = useRef<HTMLDivElement>(null)
  const formatCurrency = useFormatCurrency()
  const checkoutRouter = useCheckoutRouter()
  const isUserLoggedIn = useReactiveVar(jwtVar) !== null
  const isOutletPageRoute = useRouteMatch({
    path: `/${merchantURLPath}/${outletFulfilment.outlet.id}/`,
  })

  const locationRequired = [
    CurrentFulfilmentType.DELIVERY_EVERYWHERE,
    CurrentFulfilmentType.TABLE_UNSELECTED,
  ].includes(outletFulfilment.data.currentFulfilment.type)

  // interval for updating deliveryWindow when expired
  const { updateFulfilmentTimesIfExpired, outlet } = outletFulfilment

  // these 2 useEffects do an initial update of the basket's fulfilment times
  // and then updates them every minute
  // but only if this is not on the outlet's page route, in which case it is handled by the OutletPageStateWrapper
  useEffect(() => {
    // run on first load in case of in case of expired basket times
    if (!isOutletPageRoute && outlet.isOrderable) {
      updateFulfilmentTimesIfExpired()
    }
  }, [outlet.isOrderable])
  useEffect(() => {
    // this useEffect is separate to the initial one above as the `updateFulfilmentTimesIfExpired`
    // function gets redefined as a result of `updateFulfilmentTimesIfExpired` being called
    // and we don't want to get in a loop
    // so don't add anything else to this useEffect
    if (!isOutletPageRoute && outlet.isOrderable) {
      const interval = setInterval(() => {
        updateFulfilmentTimesIfExpired()
      }, ONE_MINUTE)
      return () => clearInterval(interval)
    }
  }, [outlet.isOrderable, isOutletPageRoute, updateFulfilmentTimesIfExpired])

  const minimumSubtotal = () => {
    // default to 30p minimum basket: stripe will not process amounts under 30p
    if (
      outletFulfilment.data.currentFulfilment.narrowType ===
      NarrowFulfilmentMethodInputType.DELIVERY
    )
      return outletFulfilment.outlet.deliveryMinimumOrderValue || 30
    if (
      outletFulfilment.data.currentFulfilment.narrowType ===
      NarrowFulfilmentMethodInputType.COLLECTION
    )
      return outletFulfilment.outlet.collectionMinimumOrderValue || 30
    return 30
  }

  const minimumSubtotalReached = () => {
    // assume true until basket totals are returned
    if (!basketTotals.data) {
      return true
    }

    const itemsTotal =
      basketTotals.data.checkoutBasketTotals?.nonAddOnBasketItemsTotal

    // default to true to hide message in case of empty/persistent basket
    if (!itemsTotal) return true
    return itemsTotal >= minimumSubtotal()
  }

  const scrollToMinimumSubtotalError = () => {
    if (minimumSubtotalErrorRef.current) {
      minimumSubtotalErrorRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }
  const disableOrdering =
    !marketplace.featureOrdering ||
    !marketplace.featureLogin ||
    !marketplace.featureRegister

  const buttonText = () => {
    if (disableOrdering) {
      return t('coming_soon')
    }
    if (isUserLoggedIn) {
      return toUpper(t('checkout'))
    }
    return toUpper(t('sign_in_and_checkout'))
  }

  if (!basketItems.items.length) {
    return <ErrorPage />
  }

  const { outletPhone, restaurant, displayName } = outletFulfilment.outlet

  const getOrderNotePlaceholder = () => {
    if (marketplace.orderNoteText) return marketplace.orderNoteText
    else
      return marketplace.merchantType === MerchantType.RETAIL
        ? t('order_note_example_retail')
        : t('order_note_example')
  }

  return (
    <>
      {locationRequired && (
        <Formik onSubmit={() => void 0} initialValues={{}}>
          {/* dumbest thing ever - the table picker uses <SelectInput /> which uses useField */}
          <LocationRequiredAlert />
        </Formik>
      )}

      <Container
        windowHeight={window.innerHeight}
        hasSafeArea={safeAreaInsetBottom > 0}
      >
        <SpaceBetweenRow>
          <Subheader
            passthroughQueryParams={false}
            to={`/${merchantURLPath}/${outletFulfilment.outlet.id}/${kebabCase(
              displayName
            )}`}
          >
            <RestaurantLogo
              role="img"
              aria-label={`${outletFulfilment.outlet.displayName} logo`}
              imageUrl={imageJitURL(
                outletFulfilment.outlet.outletLogoOverride ||
                  outletFulfilment.outlet.restaurant.image,
                {
                  resize: {
                    width: 64,
                    height: 64,
                    fit: 'cover',
                  },
                }
              )}
            />
            {displayName}
          </Subheader>
          <StyledClearButton onClick={() => setClearBasketWarningOpen(true)}>
            {width > breakpoints.tablet ? t('clear_basket_title') : t('clear')}
          </StyledClearButton>
        </SpaceBetweenRow>

        {!minimumSubtotalReached() && (
          <div ref={minimumSubtotalErrorRef}>
            <WarningText>
              {t('minimum_subtotal_not_reached', {
                minimumSubtotal: formatCurrency(minimumSubtotal()),
              })}
            </WarningText>
          </div>
        )}

        <FulfilmentButtonContainer
          onClick={() => {
            openServiceComponentModal()
          }}
        >
          <strong>
            {upperFirst(
              t(toLower(outletFulfilment.data.currentFulfilment.narrowType))
            )}
          </strong>
          <div>
            <p>
              <FulfilmentWhenLabel
                stateType={OutletFulfilmentStateType.GLOBAL}
              />
            </p>
            <ExpandArrows id="open" />
          </div>
        </FulfilmentButtonContainer>

        <AlertModal
          isOpen={clearBasketWarningOpen}
          title={t('clear_basket')}
          subTitle={t('clear_basket_desc')}
          action={{
            text: t('confirm'),
            intent: ButtonType.PRIMARY,
            onClick: () => {
              basketItems.clear({ shouldResetCheckoutRouter: true })
              outletFulfilment.updateHistoricalData({
                orderNotes: '',
                deliveryNotes: '',
              })
            },
          }}
          cancel={{
            text: t('cancel'),
            intent: ButtonType.SECONDARY,
            onClick: () => setClearBasketWarningOpen(false),
          }}
        />

        {restaurant.enableAllergyInformation && (
          <AlertModal
            isOpen={allergyNoticeOpen}
            title={t('allergy_guidance')}
            subTitle={displayName}
            desc={t('allergy_information')}
            action={{
              text: t('view_allergy_policy'),
              intent: ButtonType.PRIMARY,
              onClick: () => {
                setAllergyNoticeOpen(false)
                checkoutRouter.reset()
                setRoute({ mainRouteName: MainRouteName.ALLERGY })
              },
            }}
            onClose={() => setAllergyNoticeOpen(false)}
            cancel={
              outletPhone
                ? {
                    text: t('call_restaurant'),
                    intent: ButtonType.SECONDARY,
                    onClick: () => {
                      window.open(`tel:${outletPhone}`, '_self')
                    },
                  }
                : {
                    text: t('back'),
                    intent: ButtonType.SECONDARY,
                    onClick: () => {
                      setAllergyNoticeOpen(false)
                    },
                  }
            }
            closeTriggersCancel={false}
            headerBackgroundColor="#f6f6f9"
            showCloseButton
          />
        )}
        <BasketItems />
        <InputContainer>
          {restaurant.enableAllergyInformation && (
            <OrderNoteTitle>
              <FoodAllergyNotice onClick={() => setAllergyNoticeOpen(true)}>
                {t('allergy_notice')}
              </FoodAllergyNotice>
            </OrderNoteTitle>
          )}
          <UncontrolledTextInput
            name={'orderNoteContainer'}
            label={t('order_note')}
            value={outletFulfilment.data.historicalData.orderNotes || ''}
            touched
            placeholder={getOrderNotePlaceholder()}
            required={false}
            onChange={e => {
              outletFulfilment.updateHistoricalData({
                orderNotes: e.target.value,
              })
            }}
          />
        </InputContainer>
        {outletFulfilment.data.currentFulfilment.narrowType !==
          NarrowFulfilmentMethodInputType.TABLE &&
        basketTotals.data?.checkoutBasketTotals?.nonAddOnBasketItemsTotal ? (
          <DiscountCodeSection
            basketSubTotal={
              basketTotals.data.checkoutBasketTotals?.nonAddOnBasketItemsTotal
            }
            outletId={outletFulfilment.outlet.id}
            appliedDiscount={
              basketTotals.data.checkoutBasketTotals?.appliedDiscount
                ?.discount || null
            }
          />
        ) : null}

        <Totals />
      </Container>

      <CheckoutButtonWrapperMobile>
        <CheckoutButton
          content={buttonText()}
          type="button"
          disabled={disableOrdering}
          onClick={() => {
            // Do not disable the checkout button. Scroll to errors on click.
            if (!minimumSubtotalReached()) {
              scrollToMinimumSubtotalError()
              return
            }

            ReactPixel.track('InitiateCheckout', {
              value:
                basketTotals.data?.checkoutBasketTotals
                  ?.nonAddOnBasketItemsTotal,
              currency: marketplace.country.currency.iso4217,
              content_ids: basketItems.items.map(item => item.id),
              content_type: 'product',
              num_items: basketItems.items.reduce(
                (acc, item) => acc + item.quantity,
                0
              ),
            })

            checkoutRouter.override(CheckoutRoute.FULFILMENT)
          }}
          dataTestId="basket-summary-checkout-button"
        />
      </CheckoutButtonWrapperMobile>
    </>
  )
}
